new Vue({
  el: '#footer',
  data() {
    return {
      open: false,
    }
  },
  methods: {
    toggleOpen() {
      this.open = !this.open;
    }
  }
});